import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const TrafficDUI = () => (
  <Layout>
    <PageTitle title="Traffic and DUI/DWI Lawyer" page="PageTitleTraffic" />
    <Seo title="Traffic and DUI/DWI Lawyer in Annapolis MD" description="The Law Office of Sean E. Becker, LLC handles various major and minor traffic charges throughout Maryland in court and at the MVA hearings. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            The Law Office of Sean E. Becker, LLC handles various major and minor traffic charges throughout Maryland in court and at the MVA hearings. All traffic charges can have major implications both in the Court and at the MVA. It is important to address traffic matters immediately. Sean will review and discuss all of your options regarding any court matters and license ramifications with the MVA. Sean Becker is committed to providing excellent legal representation and customer service for all of our clients.
            </p>

        </div> 
    </div>        
  </Layout>
)

export default TrafficDUI
